@font-face {
  font-family: "GinoraSans";
  src: local("GinoraSans"), url(./assets/fonts/GinoraSans.otf) format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "GinoraSans";
  src: local("GinoraSans"), url(./assets/fonts/GinoraSans-Bold.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}

@import "../node_modules/bootstrap/scss/bootstrap";


:root {
  --font-family-sans-serif: "GinoraSans", serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: "GinoraSans", serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  cursor: url(./assets/icons/cursor.png), auto;
}

body.edit {
  cursor: url(./assets/icons/edit-cursor.png), auto;
}

button:not(:disabled) {
  cursor: url(./assets/icons/hover-cursor.png), auto;
}

.clickable {
  cursor: url(./assets/icons/hover-cursor.png), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tooltip {
  --#{$prefix}tooltip-color: #{$tooltip-bg};
  --#{$prefix}tooltip-bg: #{$tooltip-color};
}

canvas {
  display: block;
}
